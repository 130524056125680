import React, {useState, useEffect} from 'react';
const newHome = () => {


    return ( 
        <div className= 'home'>
          <div className = "home-top">
            <h1>testing this out </h1>
          </div>
          <div className = "home-bottom">
            <p>testing this again</p>
          </div>
        </div>  
        )
}

  
  export default newHome;
